/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
// import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { getUserByToken, login, verifyOTP } from '../core/_requests';
import { useAuth } from '../core/Auth';
import Select from 'react-select';
import { toast } from 'react-toastify';

const countries: Array<any> = [
	{ value: '+1', label: '(+1) United States', flag: '/media/flags/united-states.svg' },
	{ value: '+91', label: '(+91) India', flag: '/media/flags/india.svg' }
]

const loginSchema = Yup.object().shape({
	phone: Yup.string()
		.required('Phone is required')
		.max(10, 'Maximum 10 chaaracter')
});

const initialValues = {
	phone: ''
};

export function Login() {
	var length = 4;
	const [currentForm, setCurrentForm] = useState('login');
	const [loading, setLoading] = useState(false);
	const { saveAuth, setCurrentUser } = useAuth();
	const [countryCode, setCountryCode] = useState("+1");

	const [otp, setOtp] = useState(Array(length).fill(''));
	const inputs = useRef<any>([]);

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			if (currentForm === "login") {
				try {
					const data = await login(countryCode, values.phone);
					if (data.status === 200) {
						setCurrentForm("otp");
						inputs.current[0].focus();
					}
					setLoading(false);
				} catch (error) {
					setLoading(false);
				}
			} else {
				try {
					const apiData = await verifyOTP(countryCode, values.phone, otp.join(""), true);
					if (apiData.status === 200) {
						try {
							saveAuth(apiData.data);
							const { data: user } = await getUserByToken(apiData.data.api_token);
							setCurrentUser(user);
						} catch (error) {
							saveAuth(undefined);
							setStatus('The registration details is incorrect');
							setSubmitting(false);
							setLoading(false);
						}
					}
					setLoading(false);
				} catch (error: any) {
					if (error.response.status === 422) {
						toast.error("Invalid OTP", {
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							theme: "colored"
						});
					}
					setLoading(false);
				}
			}
		}
	});
	const handleSelectChange = (e) => {
		setCountryCode(e.value);
	};
	const handleInputChange = (e, index) => {
		const { value } = e.target;
		// Only allow single digit input
		if (value.match(/^\d$/) || value === '') {
			const newOtp = [...otp];
			newOtp[index] = value;
			setOtp(newOtp);

			// Move focus to the next input
			if (index < length - 1 && value !== '') {
				inputs.current[index + 1].focus();
			}
		}

		// Move focus to previous input on backspace
		if (value === '' && index > 0) {
			inputs.current[index - 1].focus();
		}
	};
	const handleKeyDown = (e, index) => {
		if (e.key === 'Backspace' && otp[index] === '') {
			// Move focus to previous input on backspace if current input is empty
			if (index > 0) {
				inputs.current[index - 1].focus();
			}
		}
	};
	return (
		<form className='form w-100' onSubmit={formik.handleSubmit} id='kt_login_signin_form' noValidate>
			<div className='text-center mb-11'>
				<h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
			</div>
			{formik.status && (
				<div className='mb-lg-15 alert alert-danger'>
					<div className='alert-text font-weight-bold'>{formik.status}</div>
				</div>
			)}
			{currentForm === "login" ?
				<>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>Select a country</label>
						<Select
							className="react-select-styled"
							classNamePrefix='react-select'
							options={countries}
							formatOptionLabel={country => (
								<div className='label'>
									<img src={country.flag} alt='flag' className='w-20px rounded-circle me-2' />
									<span>{country.label}</span>
								</div>
							)}
							placeholder='Select an option'
							defaultValue={countries[0]}
							onChange={(e) => handleSelectChange(e)}
						/>
					</div>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>Phone</label>
						<input placeholder='Phone' type='text' autoComplete='off' {...formik.getFieldProps('phone')} className={clsx('form-control bg-transparent', { 'is-invalid': formik.touched.phone && formik.errors.phone }, { 'is-valid': formik.touched.phone && !formik.errors.phone })} />
						{formik.touched.phone && formik.errors.phone && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'><span role='alert'>{formik.errors.phone}</span></div>
							</div>
						)}
					</div>
					{/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
						<Link to='/auth/forgot-password' className='link-primary'>Forgot Password ?</Link>
					</div> */}
				</>
				: null}
			{currentForm === "otp" ?
				<>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>OTP</label>
						<div className="d-flex justify-content-between">
							{otp.map((_, index) => (
								<input
									key={index}
									type="text"
									maxLength={1}
									value={otp[index]}
									onChange={(e) => handleInputChange(e, index)}
									onKeyDown={(e) => handleKeyDown(e, index)}
									ref={(el) => (inputs.current[index] = el)}
									className="form-control h-60px w-60px fs-2qx text-center mx-1 my-2"
								/>
							))}
						</div>
					</div>
				</>
				: null}
			<div className='d-grid mb-10'>
				<button type='submit' id='kt_sign_in_submit' className='btn btn-primary' disabled={formik.isSubmitting || !formik.isValid}>
					{!loading && <span className='indicator-label'>Continue</span>}
					{loading && (
						<span className='indicator-progress' style={{ display: 'block' }}>Please wait...<span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>
					)}
				</button>
			</div>
			{/* <div className='text-gray-500 text-center fw-semibold fs-6'>
				Not a Member yet?{' '}
				<Link to='/auth/registration' className='link-primary'>Sign up</Link>
			</div> */}
		</form>
	)
}