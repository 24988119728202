import axios from 'axios';
import { AuthModel, UserModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/login-by-token`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const REGISTER_URL = `${API_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`;

export const SEND_OTP = `${API_URL}/auth/send-otp`;
export const VERIFY_OTP = `${API_URL}/auth/verify-otp`;

// Server should return AuthModel
export function login(country_code: string, phone: string) {
	return axios.post<AuthModel>(LOGIN_URL, { country_code: country_code, phone: phone });
}

// Server should return AuthModel
export function register(name: string, email: string, country_code: string, phone: string) {
	return axios.post(REGISTER_URL, { email: email, name: name, country_code: country_code, phone: phone, platform: 0 });
};

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
	return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken(token: string) {
	return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, { api_token: token });
}

export function sendOTP(country_code: string, phone: string) {
	return axios.post(SEND_OTP, { country_code: country_code, phone: phone, platform: 0 });
};

export function verifyOTP(country_code: string, phone: string, otp: string, login: boolean) {
	if (login) {
		return axios.post(VERIFY_OTP, { country_code: country_code, phone: phone, otp: otp, platform: 0, type: "login" });
	} else {
		return axios.post(VERIFY_OTP, { country_code: country_code, phone: phone, otp: otp });
	}
};