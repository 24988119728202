/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { getUserByToken, register, sendOTP, verifyOTP } from '../core/_requests';
import { Link } from 'react-router-dom';
import { useAuth } from '../core/Auth';
import Select from 'react-select';
import { toast } from 'react-toastify';

const initialValues = {
	name: '',
	email: '',
	phone: ''
};

const countries: Array<any> = [
	{ value: '+1', label: '(+1) United States', flag: '/media/flags/united-states.svg' },
	{ value: '+91', label: '(+91) India', flag: '/media/flags/india.svg' }
]

const registrationSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, 'Minimum 3 chaaracter')
		.max(50, 'Maximum 50 chaaracter')
		.required('Name is required'),
	email: Yup.string()
		.email('Wrong email format')
		.required('Email is required'),
	phone: Yup.string()
		.required('Phone is required')
		.max(10, 'Maximum 10 chaaracter')
});

export function Registration() {
	const [currentForm, setCurrentForm] = useState('register');
	const [loading, setLoading] = useState(false);
	const [countryCode, setCountryCode] = useState("+1");
	const [otp, setOTP] = useState("");
	const { saveAuth, setCurrentUser } = useAuth();
	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			if (currentForm === "register") {
				try {
					const data = await sendOTP(countryCode, values.phone);
					if (data.status === 200) {
						setCurrentForm("otp");
					}
					setLoading(false);
				} catch (error) {
					setLoading(false);
				}
			} else if (currentForm === "otp") {
				try {
					const data = await verifyOTP(countryCode, values.phone, otp, false);
					if (data.status === 200) {
						try {
							const { data: auth } = await register(
								values.name,
								values.email,
								countryCode,
								values.phone
							);
							saveAuth(auth);
							const { data: user } = await getUserByToken(auth.api_token);
							setCurrentUser(user);
						} catch (error) {
							saveAuth(undefined);
							setStatus('The registration details is incorrect');
							setSubmitting(false);
							setLoading(false);
						}
					}
					setLoading(false);
				} catch (error: any) {
					if (error.response.status === 422) {
						toast.error("Invalid OTP", {
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							theme: "colored"
						});
					}
					setLoading(false);
				}
			}
		}
	});
	const handleSelectChange = (e) => {
		setCountryCode(e.value);
	};
	const handleInputChange = (e) => {
		setOTP(e.target.value);
	};
	return (
		<form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' id='kt_login_signup_form' onSubmit={formik.handleSubmit} noValidate>
			<div className='text-center mb-11'>
				<h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
			</div>
			{formik.status && (
				<div className='mb-lg-15 alert alert-danger'>
					<div className='alert-text font-weight-bold'>{formik.status}</div>
				</div>
			)}
			{currentForm === "register" ?
				<>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>Name</label>
						<input placeholder='Name' type='text' autoComplete='off' {...formik.getFieldProps('name')} className={clsx('form-control bg-transparent', { 'is-invalid': formik.touched.name && formik.errors.name }, { 'is-valid': formik.touched.name && !formik.errors.name })} />
						{formik.touched.name && formik.errors.name && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'><span role='alert'>{formik.errors.name}</span></div>
							</div>
						)}
					</div>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>Email</label>
						<input placeholder='Email' type='email' autoComplete='off' {...formik.getFieldProps('email')} className={clsx('form-control bg-transparent', { 'is-invalid': formik.touched.email && formik.errors.email }, { 'is-valid': formik.touched.email && !formik.errors.email })} />
						{formik.touched.email && formik.errors.email && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'><span role='alert'>{formik.errors.email}</span></div>
							</div>
						)}
					</div>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>Select a country</label>
						<Select
							className="react-select-styled"
							classNamePrefix='react-select'
							options={countries}
							formatOptionLabel={country => (
								<div className='label'>
									<img src={country.flag} alt='flag' className='w-20px rounded-circle me-2' />
									<span>{country.label}</span>
								</div>
							)}
							placeholder='Select an option'
							defaultValue={countries[0]}
							onChange={(e) => handleSelectChange(e)}
						/>
					</div>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>Phone</label>
						<input placeholder='Phone' type='text' autoComplete='off' {...formik.getFieldProps('phone')} className={clsx('form-control bg-transparent', { 'is-invalid': formik.touched.phone && formik.errors.phone }, { 'is-valid': formik.touched.phone && !formik.errors.phone })} />
						{formik.touched.phone && formik.errors.phone && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'><span role='alert'>{formik.errors.phone}</span></div>
							</div>
						)}
					</div>
				</>
				: null}
			{currentForm === "otp" ?
				<>
					<div className='fv-row mb-8'>
						<label className='form-label fw-bolder text-dark fs-6'>OTP</label>
						<input placeholder='OTP' type='text' autoComplete='off' className="form-control" onChange={(e) => handleInputChange(e)} />
					</div>
				</>
				: null}
			<div className='text-center'>
				<button type='submit' id='kt_sign_up_submit' className='btn btn-lg btn-primary w-100 mb-5' disabled={formik.isSubmitting || !formik.isValid}>
					{!loading && <span className='indicator-label'>Submit</span>}
					{loading && (
						<span className='indicator-progress' style={{ display: 'block' }}>Please wait...{' '}<span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>
					)}
				</button>
				<Link to='/auth/login'>
					<button type='button' id='kt_login_signup_form_cancel_button' className='btn btn-lg btn-light-primary w-100 mb-5'>Cancel </button>
				</Link>
			</div>
		</form>
	)
}