import { createContext } from 'react';
import { io } from 'socket.io-client';

export const socket: any = io(`${process.env.REACT_APP_SOCKET_URL?.toString()}`, {
    forceNew: true,
    reconnectionAttempts: Infinity,
    timeout: 60000,
    transports: ["websocket", "polling"],
    secure: true
});
socket.on('connect', function () {
    // console.log("connection established");
});
export const SocketContext = createContext(socket);