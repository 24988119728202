import { createRoot } from 'react-dom/client';
import axios from 'axios';
import './_metronic/assets/fonticon/fonticon.css';
import './_metronic/assets/keenicons/duotone/style.css';
import './_metronic/assets/keenicons/outline/style.css';
import './_metronic/assets/keenicons/solid/style.css';
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import 'react-toastify/dist/ReactToastify.css';
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider, setupAxios } from './app/modules/auth';
setupAxios(axios);

const container = document.getElementById('root')
if (container) {
	createRoot(container).render(
		<>
			<AuthProvider>
				<AppRoutes />
			</AuthProvider>
		</>
	)
}