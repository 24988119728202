import clsx from 'clsx';
import { useEffect } from 'react';
import { ILayout, useLayout } from '../../core';

const Footer = () => {
	const { config } = useLayout();
	useEffect(() => {
		updateDOM(config)
	}, [config]);
	return (
		<>
			<div className='text-dark order-2 order-md-1'>
				<span className='text-muted fw-semibold me-1'>
					{new Date().getFullYear().toString()}&copy;
				</span>
			</div>
		</>
	)
}
const updateDOM = (config: ILayout) => {
	if (config.app?.footer?.fixed?.desktop) {
		document.body.classList.add('data-kt-app-footer-fixed', 'true');
	}
	if (config.app?.footer?.fixed?.mobile) {
		document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true');
	}
}

const FooterWrapper = () => {
	const { config } = useLayout();
	if (!config.app?.footer?.display) {
		return null;
	}
	return (
		<div className='app-footer' id='kt_app_footer'>
			{config.app.footer.containerClass ? (
				<div className={clsx('app-container', config.app.footer.container === 'fixed' ? 'container-xxl' : 'container-fluid', config.app.footer.containerClass)}>
					<Footer />
				</div>
			) : (
				<Footer />
			)}
		</div>
	)
}
export { FooterWrapper }