import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { AuthInit } from './modules/auth';
import { ThemeModeProvider } from '../_metronic/partials';
import { ToastContainer } from 'react-toastify';
import { SocketContext, socket } from './context/socket';

const App = () => {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<LayoutProvider>
				<ThemeModeProvider>
					<AuthInit>
						<SocketContext.Provider value={socket}>
							<Outlet />
							<MasterInit />
						</SocketContext.Provider>
					</AuthInit>
				</ThemeModeProvider>
			</LayoutProvider>
			<ToastContainer />
		</Suspense>
	)
}
export { App }